import React, {PropsWithChildren, useEffect} from 'react';
import {analyticsAction, getBlogPageTrackingProperties, getDefaultPageTrackingProperties, TRACK_ACTION} from '../utils/analytics';

type Props = {
  location: {
    pathname: string;
    href: string;
    search: string;
  };
  data: {
    datoCmsBlogpost: {
      author: {name: string};
      meta?: {updatedAt: string};
      category?: {title: string};
      title?: string;
    };
    datoCmsPage?: {titleForSeo: string};
  };
};

export const PageViewTracker: React.FC<PropsWithChildren<Props>> = ({children, ...otherProps}) => {
  useEffect(() => {
    const trackingProps =
      'datoCmsBlogpost' in (otherProps.data || {}) ? getBlogPageTrackingProperties(otherProps) : getDefaultPageTrackingProperties(otherProps);

    analyticsAction(otherProps.location.pathname, trackingProps, TRACK_ACTION.PAGE);
  }, [otherProps?.location?.pathname]);

  return <>{children}</>;
};
