export const isString = (value?: unknown) => Object.prototype.toString.call(value) === '[object String]';

/*
 *  Splits string and returns specific position
 *  By default if position is out of range it returns
 *  what's on position 0
 *  Elements can be accessed backwards by using negative numbers
 *  where -1 is the last element.
 *  Does not work like split() function by default, to make it so, then pass false for trim parameter
 * */
export const splitAndGet = (
  value: string,
  splitBy: string,
  pos = 0,
  trim: boolean = true, // Remove splitBy character if it's at the start and/or end of value to avoid empty values
  raiseException = false
): string | undefined | null => {
  // Against all odds, a non string value was passed
  if (!isString(value)) {
    return null;
  }

  if (trim) {
    const endIndex = value.charAt(value.length - 1) == splitBy ? -1 : undefined,
      startIndex = value.charAt(0) === splitBy ? 1 : 0;
    value = value.slice(startIndex, endIndex);
  }

  const result = value.split(splitBy);
  pos = pos < 0 ? result.length + pos : pos;
  if (pos < 0 || pos >= result.length) {
    if (raiseException) {
      throw new RangeError(`Parameter pos ${pos} is out of range, where range is up to ${result.length}`);
    }
    pos = 0;
  }
  return result[pos];
};

/*
 * Returns the 'pos' character of a given string
 * Removes any accent the character at index 'pos' may have
 * If an empty string is passed, the same is returned
 * If a non string variable is passed in "text", an empty string is returned
 * If an index is passed out of range, an empty string is returned
 */
export const getUnaccentedCharAt = (text: string, pos?: number): string => {
  if (!isString(text) || text === '') {
    return '';
  }

  return text
    .charAt(pos)
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
};
