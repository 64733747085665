import {getCookie} from './cookie';
import {splitAndGet} from './stringUtils';
import {SyntheticEvent} from 'react';
import * as Sentry from '@sentry/browser';

export function gtmDataAnalyticsPush<T>({
  eventName,
  category,
  label,
  action,
  data,
  callback
}: {
  eventName: string;
  category: string;
  label: string;
  action: string;
  data?: {[key: string]: T};
  callback?: () => void;
}): void {
  gtmDataPush(
    {
      ...(data || {}),
      event: eventName,
      'r2o-ws_event_category': category,
      'r2o-ws_event_label': label,
      'r2o-ws_event_action': action
    },
    callback
  );
}

export function gtmDataPush(data: {event: string; [key: string]: unknown}, eventCallback?: (() => void) | null): void {
  const w = window;

  if (w.dataLayer) {
    if (eventCallback) {
      data = {
        ...data,
        eventCallback: trackAndInsureCallbackExecution(eventCallback)
      };
    }
    w.dataLayer.push(data);
  } else if (eventCallback) {
    eventCallback();
  }
}

export enum TRACK_EVENT {
  SIGNUP_FORM_CLICKED = 'signup form field clicked',
  SIGNUP_FORM_CHANGED = 'signup form field changed',
  SIGNUP_FORM_TRACKING = 'signup_form_tracking',
  TRIAL_REQUESTED = 'free trial requested',
  SOCIAL_ICONS = 'social icons clicked',
  TRUST_LINK = 'trust link clicked',
  APP_DOWNLOAD = 'app download icons clicked',
  LOGIN_BUTTON_CLICKED = 'login button clicked'
}

export enum TRACK_ACTION {
  TRACK = 'track',
  IDENTIFY = 'identify',
  PAGE = 'page',
  TRACK_FORM = 'trackForm'
}

export const signupFormErrorTrack = (error: string, field: string, origin: string): void => {
  const event = TRACK_EVENT.SIGNUP_FORM_TRACKING;
  analyticsAction(event, {message: `Field ${field} - ${error} - Validation Error ${origin} Side`}, TRACK_ACTION.TRACK_FORM);
};

export const signupFormFieldTrack = (e: SyntheticEvent, fieldName: string): void => {
  const trackingMessage = e.type === 'click' ? TRACK_EVENT.SIGNUP_FORM_CLICKED : TRACK_EVENT.SIGNUP_FORM_CHANGED;
  analyticsAction(trackingMessage, {fieldName});
};

type TrackType = {
  event: string | TRACK_EVENT;
  data?: {[key: string]: string | number};
};

export const getTrackOnClick = (track: TrackType) => (): void => {
  analyticsAction(track.event, track.data || {});
};

export const analyticsAction = (event: string | TRACK_EVENT, data: {[key: string]: unknown} = {}, action = TRACK_ACTION.TRACK) => {
  if (window?.analytics && window?.analytics[action]) {
    try {
      window.analytics[action](event, data);
    } catch (error) {
      Sentry.captureException(error);
    }
  }
};

export const getDefaultPageTrackingProperties = (props: {
  location: {
    pathname: string;
    href: string;
    search: string;
  };
  data: {datoCmsPage?: {titleForSeo: string}};
}) => ({
  ga_client_id: getCookie('_ga'),
  hit_timestamp: getHitTimeStamp(),
  is_internal: !!getCookie('internal'), //default to false if cookie not present
  is_karriere: !!getCookie('karriere'), //default to false if cookie not present
  page_language: locationToLocale(splitAndGet(props.location.pathname, '/')),
  // Override Segment default values for consistency
  path: props.location.pathname,
  title: props.data?.datoCmsPage?.titleForSeo,
  url: props.location.href,
  referrer: window?.onRouteUpdateGatsbyPrevLocation,
  search: props.location?.search || ''
});

export const getBlogPageTrackingProperties = (props: {
  location: {
    pathname: string;
    href: string;
    search: string;
  };
  data: {
    datoCmsBlogpost: {
      author: {name: string};
      meta?: {updatedAt: string};
      category?: {title: string};
      title?: string;
    };
    datoCmsPage?: {titleForSeo: string};
  };
}) => {
  const blogProps = props.data.datoCmsBlogpost;
  const defaultProperties = getDefaultPageTrackingProperties(props);
  return {
    ...defaultProperties,
    ...{
      blog_author: blogProps?.author?.name || '',
      blog_last_updated: blogProps?.meta?.updatedAt || '',
      blog_category: blogProps?.category?.title || '',
      blog_title: blogProps?.title || ''
    }
  };
};

function trackAndInsureCallbackExecution(callback: () => void) {
  let callbackIsExecuted = false;

  const trackedCallback = () => {
    callbackIsExecuted = true;
    callback();
  };

  // execute callback if it's not executed after 1 sec of tracking
  // e.g. that means google analytics script has a problem
  (window as Window).setTimeout(() => {
    if (!callbackIsExecuted) {
      callback();
    }
  }, 1000);

  return trackedCallback;
}

// Expected format is yyyy-mm-ddThh:mm:ss.ms+hh:mm
export const getHitTimeStamp = () => {
  const today = new Date();
  const isoFormat = splitAndGet(today.toISOString(), 'Z', 0),
    tzo = today.getTimezoneOffset(); // timezoneOffset returned in minutes
  const tzoHours = Math.abs(Math.floor(tzo / 60))
      .toString()
      .padStart(2, '0'),
    tzoMinutes = (tzo % 60).toString().padStart(2, '0');

  return `${isoFormat}${tzo >= 0 ? '+' : '-'}${tzoHours}:${tzoMinutes}`;
};

export const locationToLocale = (location: string): string => {
  const specialUriToLocale = {
    at: 'de'
  };

  return location in specialUriToLocale ? specialUriToLocale[location] : location;
};
