import * as thisModule from './cookie';

// Importing this module onto its own code
// so that getCookieExpires can be mocked in tests

// Had to export this function in order to make it testable
// since "expires" information can't be obtained from document.cookie
export const getCookieExpires = (days: number) => {
  if (days < 0) {
    throw new Error('Number of days cannot be negative');
  }
  const cookieDate = new Date();
  cookieDate.setDate(cookieDate.getDate() + days);
  return ` expires=${cookieDate.toUTCString()}; `;
};

export const setCookie = (name: string, value: string, cookieDomain?: string, setExpires: boolean = false, days?: number) => {
  const cookieExpires = setExpires ? thisModule.getCookieExpires(days || 365) : ''; // Default to 365 if days is undefined
  const domain = cookieDomain ? `domain=${cookieDomain}` : '';
  document.cookie = `${name}=${value};${cookieExpires} ${domain}; path=/`;
};

export const getCookie = (name: string): string | null => {
  if (typeof window !== 'undefined') {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  }
  return null;
};

export const getMarketingCookieValues = () => {
  const COOKIES = ['_ga', 'gclid', 'fbclid', '_fbc', '_fbp'];
  const COOKIE_VALUES = {};
  COOKIES.forEach(c => {
    const cValue = getCookie(c);
    if (cValue !== null) {
      COOKIE_VALUES[c] = cValue;
    }
  });

  return COOKIE_VALUES;
};

export enum COOKIE_NAMES {
  Accept_Cookie = 'accept_cookies'
}
